/* You can add global styles to this file, and also import other style files */
:root {
  --Azul333: #d3f0f7;
  --Negro-2: #9b9b9b;
  --Negro-1: #4e4e4e;
  --Negro-3: #d1d1d1;
  --Negro: #1f1f1f;
  --Azul111: #23b4d9;
  --Azul11 : #006DB3;
  --Azul222:#7BD2E8;
  --Primary: #545f71;
  --errorText: #b1000f;
  --Verde-oscuro: #00ae8d;
  --Outline:#9AA8BC;
  --Gris-claro : #FBFBFB;
  --Verde-1: #A7CF28;
  --Amarillo: #ffde6c;
  --Naranja-1: #F07019;
}


@font-face {
  font-family: "Gotham Rounded";
  src: url("./assets/fonts/Gotham_Rounded_Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("./assets/fonts/Gotham_Rounded_Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("./assets/fonts/Gotham_Rounded_Light.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans_Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans_SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans_Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-VariableFont_Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-VariableFont_Regular.ttf") format("truetype");
  font-weight: 400;
}

body{
  background-color: #eaebeb;
}

/* Icons */
.cvu-icon {
  width: 24px;
  height: 24px;
  display: block;
  background-size: cover;
  background-color: var(--Negro);
  -webkit-mask: no-repeat center;
  mask: no-repeat center;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100%;
}

.cvu-icon-sorting {
  mask-image: url(/assets/icons/sorting.svg);
  -webkit-mask-image: url(/assets/icons/sorting.svg);
}
.cvu-icon-eye {
  mask-image: url(/assets/icons/visibility.svg);
  -webkit-mask-image: url(/assets/icons/visibility.svg);
}
.cvu-icon-left-arrow {
  mask-image: url('/assets/icons/left-arrow.svg');
  -webkit-mask-image: url('/assets/icons/left-arrow.svg');
}
.cvu-icon-first {
  mask-image: url('/assets/icons/Final izq.svg');
  -webkit-mask-image: url('/assets/icons/Final izq.svg');
}
.cvu-icon-last {
  mask-image: url('/assets/icons/Final Derecho.svg');
  -webkit-mask-image: url('/assets/icons/Final Derecho.svg');
}
.cvu-icon-next {
  mask-image: url(/assets/icons/Siguiente.svg);
  -webkit-mask-image: url(/assets/icons/Siguiente.svg);
}
.cvu-icon-prev {
  mask-image: url(/assets/icons/Atras.svg);
  -webkit-mask-image: url(/assets/icons/Atras.svg);
}
